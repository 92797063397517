/*
* === General Style & Positioning ===
*/

.Basics {
	max-height: 1200px;
	max-width:  600px;
	height: 100%;
	color: var(--off-black);
	background-color: var(--dark-white);
	margin:  auto;
}

.Basics.SmallUp {
	padding: 50px;
	font-size: 18px;
}

.Basics.XSmall {
	padding: 4%;
	font-size: 15px;
}

.Basics .CardContainer {
	width: 100%;

}



