/*
* === General Style & Positioning ===
*/
.CasePage {
	max-width:  600px;
	height: 100%;
	color: var(--off-black);
	margin:  auto;
}

/*---new---*/

/*---------*/

.CasePage.SmallUp {
	padding: 30px;
	font-size: 18px;
}

.CasePage.XSmall {
	padding: 0%;
	font-size: 16px;
}

.CasePage .content {
	padding:  5px;
}

.CasePage h1 {
	color: #EE6C2C;
}
.CasePage h3 {
	font-size:  28px;
	color:  var(--dark-red);
}

.CasePage .case_img{
    max-width: 350px;
}



