/*
* === General Style & Positioning ===
*/

.Cases {
	/*max-height: 1200px;*/
	max-width:  600px;
	height: 100%;
	color: var(--off-black);
	background-color: var(--dark-white);
	margin:  auto;
}

.Cases.SmallUp {
	padding: 50px;
	font-size: 18px;
}

.Cases.XSmall {
	padding: 4%;
	font-size: 16px;
}

.Cases.CardContainer {
	width: 100%;

}




