/*
* === General Style & Positioning ===
*/

.Home {
	height: 100%;
	max-width:  600px;
	margin:  auto;
	color: var(--off-black);
	background-color: white;
	/*display: flex;
	justify-content: center;*/	
}

.Home.SmallUp {
	padding: 50px;
	font-size: 18px;
}

.Home.XSmall {
	padding: 0;
	font-size: 16px;
}

.Home .CardContainer {
	width: 100%;
}





