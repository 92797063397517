@import '../styles/STYLING_PARAMS.scss';


.MonchNavBar {
  background-color: var(--background-plain);
  height: 50px;
  box-shadow: 0 0 10px var(--shadow-regular);
  width: 100%;

  /*This makes the bar floating*/
  position: fixed;
  z-index: 100;
}

.monch-nav-section {
  line-height: 50px;
  padding-left: 20px;
  padding-right: 20px;

  color: var(--text-light);
}

.monch-nav-button {
  font-size:14px;
  max-height: 50px;
  margin-top: 0px;
  margin-bottom:0px;
  padding-top: 0px;
  padding-bottom: 0px;
  line-height: 30px;
  font-weight: 600;
  background-color: var(--accent-primary);
}

#monch-nav-search {
  width: 20%;
  padding-left: 10px;
  float: left;
}


#monch-nav-options {
  width: 20%;
  float: right;
  text-align: right;
  padding-right: 15px;
  font-size: 18px;
}

#monch-nav-logo {
  width: 100%;
  text-align: center;
  float: none;

  font-size: 18px;
  font-weight: 600;
  color: var(--accent-primary);
}


#search_term {
  height: 36px;
  margin-top: 7px;
}


i:hover {
  color: var(--text-medium);
  cursor: pointer;
}

.icon-alert {
  color:  red;
  animation: flashingicon 1000ms infinite;
}


@keyframes flashingicon {
    0% { 
      color: brown;
    }
    50% {
      color: var(--yellow);
    }

    100% {
      color: brown;
    }
}

#monch-nav-logo:hover {
  cursor: pointer;
}


.language-toggle.selected {
  color: var(--text-medium);
  font-weight: 700;

}
.language-toggle:hover {
  color: var(--text-medium);
  cursor: pointer;
}







