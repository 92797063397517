@import '../styles/STYLING_PARAMS.scss';

.LoaderButton {
	width: 100%;
	min-height: var(--button-height-large);
}

.LoaderButton[disabled] {
	cursor: not-allowed;
	background-color: var(--light-gray);
}

.LoaderButton .spinning {
	margin-right: 0px;
	top: 2px;
	animation: spin 2s infinite linear;
	float: right;
}

.LoaderButton.standalone {
	min-width: 300px;
	min-height: var(--button-height-xlarge);
	border-radius: var(--button-radius-xlarge);
}

@keyframes spin {
	from { transform: scale(1) rotate(0deg); }
	to {transform: scale(1) rotate(360deg);}
}