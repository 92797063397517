/*
* === General Style & Positioning ===
*/

.SurveyQuestion {
	margin-bottom:  30px;
}

.SurveyQuestion .SubQuestion {
	margin-top:  10px;
	padding-left:  60px;
	margin-bottom:  10px;
	font-size:  16px;
}

.SurveyQuestion input[type="radio"] {
	margin-left:  5px;
	margin-right: 8px;
	font-size:  16px;
}

.SurveyQuestion input[type="checkbox"] {
	margin-left:  5px;
	margin-right: 8px;
	font-size:  16px;
  }