.ContentCard {
	color: var(--dark-gray);

	width: 100%;
	height: 100px;

	margin-top: 10px;
	padding: 20px;
	padding-left: 5%;
	padding-right: 5%;

	text-align: center;
	background-color: white;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

.ContentCard:hover {
	cursor:  pointer;
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.ContentCard .text {
	margin-left:  10px;
	line-height: 55px;
	text-align: left;
}


.ContentCard .text .two-lines {
	margin-right:  10px;
	margin-top:  10px;
	line-height: 20px;
	text-align: left;
}



