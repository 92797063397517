/*
* === General Style & Positioning ===
*/

.TrafficLanding {
	max-width:  600px;
	margin:  auto;
	color: var(--off-black);	
}

.TrafficLanding.SmallUp {
	padding: 50px;
	font-size: 18px;
}

.TrafficLanding.XSmall {
	padding: 5px;
	font-size: 16px;
}
.TrafficLanding_img {
	max-width:  100%;
	margin:  auto;	
}



