@import '../../styles/STYLING_PARAMS.scss';

/* all the .modal stuff is for AuthModal.js component */

#auth-modal h2 {
	line-height: 48px;
}
#auth-modal .modal-dialog .modal-content {
	display: block;
	opacity: 1;
}
#auth-modal .modal-backdrop.show {
	background-color: var(--shadow-dark);
	opacity: 0.6;
}

#auth-modal .modal-close-button {
	width: 100%;
	font-size: 20px;
	text-align: right;
	color: var(--shadow-dark);
}

#auth-modal .modal-header, 
#reminder-modal .modal-header,
#reminder-modal .modal-footer {
	border: 0;
	height: 40px;
}


#auth-modal .modal-footer {
	border: 0;
	height: 100%;
	display: block;
	text-align: center;
}

.icon-area {
	padding-top: 10px;
	border-radius: 10px
}
.icon-area:hover {
	cursor: pointer;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

/*#auth-modal .modal-footer .icon-box {
	padding-top: 15px;
	padding-bottom: 20px;
	padding-left: 15%;
	padding-right: 15%;
	display: flex;
	justify-content: space-around;
}*/

#auth-modal .modal-body {
	padding-top: 0;
	padding-bottom: 0;
}

#auth-modal .modal-body p{
	margin-top: -8px;
	font-size: 18px;
	color: var(--font-color);
	font-weight: 300;
}

#auth-modal .modal-body .forgot-password {
	font-size: 14px;
	font-weight: 400;
	margin: -5px 0 20px 8px;
}

#auth-modal .modal-body .notes {
	color: var(--accent-secondary);
	font-size: 12px;
	font-weight: 400;
	font-style: italic;
	margin: -9px 0 5px 8px;
}

#reminder-modal .modal-content {
	background-color: rgba(255, 255, 255, 0.98);
}

#reminder-modal p {
	text-align: center;
	padding: 0;
	margin: 0;
}

/* other components */ 

.ForgotPassword,
.ResetPassword,
.VerifyEmail {
	width: 300px;
	height: 100vh;
	margin: 0 auto;
	margin-top: 15vh;
	text-decoration: none;
}

.VerifyEmail .spinning {
	margin: 0 auto;
	top: 2px;
	animation: spin 2s infinite linear;
	color: var(--shadow-regular);
}

.VerifyEmail .icon-div, .VerifyEmail h5 {
	margin: 0 auto;
	text-align: center;
}

.VerifyEmail .success {
	color: green;
}

.VerifyEmail .warning {
	color: var(--yellow);
}

@keyframes spin {
	from { transform: scale(1) rotate(0deg); }
	to {transform: scale(1) rotate(360deg);}
}