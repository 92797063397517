/*
* === General Style & Positioning ===
*/

.TrafficTool {
	max-width:  600px;
	margin:  auto;
	color: var(--off-black);
}

.TrafficTool h1 {
	font-size:  22px;
	line-height:  25px;
}

.TrafficTool h2 {
	font-size:  19px;
	line-height:  23px;
	padding-bottom: 20px;
}

.TrafficTool h5{
	font-size: 1.5rem;
	line-height:  23px;
	margin-bottom: 0.5rem;
}

.TrafficTool h3 {
	font-size:  17px;
	line-height:  20px;
}

.TrafficTool h4 {
	font-size:  18px;
	line-height:  21px;
}

.TrafficTool.SmallUp {
	padding: 50px;
	font-size: 17px;
}

.TrafficTool.XSmall {
	padding: 5px;
	font-size: 16px;
}

.TrafficTool input[type="radio"] {
	margin-left:  5px;
	margin-right: 8px;
	font-size:  16px;
}

.TrafficTool input[type="checkbox"] {
	margin-left:  5px;
	margin-right: 8px;
	font-size:  16px;
}




