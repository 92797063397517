.IconCard {
	color: var(--dark-gray);

	width: 100%;
	height: 190px;

	margin-top: 10px;
	padding: 0;

	text-align: center;
	background-color: white;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
	margin-top:  10px;
	margin-bottom:  10px;

}

.IconCard:hover {
	box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
	cursor:  grab;
}

.IconCard  .card-text{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
}
.card-img-top {
    width: 100%;
    height: 150px;
    object-fit: cover;
}