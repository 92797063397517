/*
* === General Style & Positioning ===
*/
.EmergPage {
	max-width:  600px;
	height: 100%;
	color: var(--off-black);
	margin:  auto;
}

.EmergPage.SmallUp {
	padding: 30px;
	font-size: 18px;
}

.EmergPage.XSmall {
	padding: 0%;
	font-size: 16px;
}

.EmergPage .content {
	padding:  5px;
}
.EmergPage h1 {
	color:  #EE6C2C;
}
.EmergPage h3 {
	font-size:  28px;
	color:  var(--dark-red);
}

.EmergePage .case_img{
    max-width: 350px;
}