.ScenarioBox {
	margin-top:  20px;
	margin-bottom:  20px;
	background-color: var(--super-light-gray);
	border-radius: 10px;

	padding: 10px;

	text-align: left;

	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);

}

