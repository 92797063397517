@import './STYLING_PARAMS.scss';

h1, h2, h3, h4, h5, h6 {
	color: var(--dark-gray);
	font-weight: 520;
}

button {
	padding: 2%;
	padding-left: 5%;
	padding-right: 5%;

	margin-top: 5px;
	margin-bottom: 5px;

	border-radius:10px;
	border-style: none;
	background-color: #313131;
	color: white;

	font-size: 18px;
	box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24); 
}

button:disabled {
	background-color:  var(--light-gray);
	cursor:  not-allowed;
}
.emphasized-text {
	margin-top: 5px;
	margin-bottom: 5px;
	font-weight: 800;
	font-style: italic;
	font-size: 18px;
	text-shadow: 0px 0px 1px yellow;
}

.emphasized-blue, .emphasized-blue-link {
  font-weight: 600;
  color: var(--light-blue);
}

.emphasized-orange, .emphasized-orange-link {
  font-weight: 600;
  color: var(--light-orange);
}

.emphasized-blue-link:hover, .emphasized-blue-link:focus {
  color: var(--light-blue);
  text-decoration: underline;
  cursor: pointer;
}


.dark-button {
	background-color: #313131;	
	color: white;
}

.primary-button {
	background-color: var(--light-blue);
	color: var(--dark-gray);
}

.gray-button {
	background-color: var(--super-light-gray);
	color: var(--dark-gray);
}

.small-button {
	height: 25px;
	border-radius: 6ípx;
	margin-top: 5px;
	margin-bottom: 5px;
	padding-top: 0px;
	padding-bottom: 0px;
	padding-right: 10px;
	padding-left: 10px;

	font-size: 12px;
}

.dark-button.block,
.primary-button.block,
.gray-button.block {
	width: 100%;
	height: var(--button-height-large);
}

.dark-button:hover {
	box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24)
}

.dark-button.flashing {
	animation: flashing 1300ms infinite;
}

.dark-button.flashing:hover {
	background-color: #121212;
    box-shadow: 3px 2px 22px 1px rgba(255, 255, 255, 0.85);
    animation: none;
}

@keyframes flashing {
    0% { 
    	background-color: var(--dark-gray);
    	box-shadow: 3px 2px 22px 1px rgba(255, 255, 255, 0.6);
    }
    50% {
    	background-color: #121212;
    	box-shadow: 3px 2px 22px 1px rgba(255, 255, 255, 0.85);
    }

    100% {
    	background-color: var(--dark-gray);
    	box-shadow: 3px 2px 22px 1px rgba(255, 255, 255, 0.6);
    }
}




