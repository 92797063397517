/*
* === General Style & Positioning ===
*/

.BasicsPages {
	max-width:  600px;
	height: 100%;
	color: var(--off-black);
	margin:  auto;
}

/*---new---*/

/*---------*/

.BasicsPages.SmallUp {
	padding: 30px;
	font-size: 18px;
}

.BasicsPages.XSmall {
	padding: 0%;
	font-size: 16px;
}

.BasicsPages .content {
	padding:  5px;
}

.BasicsPages h3 {
	font-size:  28px;
	color:  var(--dark-red);
}

.BasicsPages .heart_nopump{
    max-width: 300px;
    justify-content: center;
}
.BasicsPages .heart_pumpswell{
    max-width: 300px;
    justify-content: center;
}
.BasicsPages .hf_risk_img{
    max-width: 350px;
    max-height: 280px;
    margin: auto;
    justify-content: center;
}