:root {
	/* the neutrals */
	--background-plain: #FFFFFF;
	--off-black: #1B1B1B;
	--darker-gray: #555555;
	--dark-gray: #4B4B4B;
	--light-gray: #A9A9A9; 
	--super-light-gray: #d9d9d9;
	--dark-white: #F7F7F7;

	/* the accents */
	--red-orange: #CF4520;
	--orange: #EE6C2C;
	--dark-red: #B31C1C;
	--yellow: #FFBF3C;
	
	/* text formatting */
	--accent-primary: var(--orange);
	--accent-primary-inactive: #B36037;
	--accent-secondary: #67B5C7;
	--accent-secondary-inactive: #4a828f;
	--accent-secondary-shadow: rgba(103,181,199,.15);
	--text-light: #B2A587;
	--text-medium: #756c58;
	--shadow-regular: #E3DBCD;
	--shadow-dark: #59564f;
	--font-color: black;


	--default-font-size: 16px;

	--button-height-large: 45px;
	--button-height-xlarge: 65px;
	--button-radius-xlarge: 20px;

	/* Progress Bar */
	--progress-bar-height: 40px;
	--progress-bar-height-half: 20px;
	--progress-bar-height-small: 30px;
	--progress-bar-line-height-small: 26px;
	--progress-bar-height-half-small: 15px;

	/* survey */
	--survey-input-width-xxsmall: 55px;
	--survey-input-width-xsmall: 75px;
	--survey-input-width-small: 100px;
	--survey-input-width-smallmedium: 150px;
	--survey-input-width-medium: 200px;
	--survey-input-width-mediumlarge: 250px;
	--survey-input-width-large: 300px;

}