/*
* === General Style & Positioning ===
*/

.SymptomsPages {
	max-width:  600px;
	height: 100%;
	color: var(--off-black);
	margin:  auto;
}

/*---new---*/

/*---------*/

.SymptomsPages.SmallUp {
	padding: 30px;
	font-size: 18px;
}

.SymptomsPages.XSmall {
	padding: 0%;
	font-size: 16px;
}

.SymptomsPages .content {
	padding:  5px;
}

.SymptomsPages h3 {
	font-size:  28px;
	color:  var(--dark-red);
}

.SymptomsPages .symptoms_img{
    max-width: 300px;
    justify-content: center;
}

.SymptomsPages .weight-check{
	max-width: 300px;
    justify-content: center;
}