.PageTitleDiv {
	color: var(--dark-gray);
	text-align: left;	
}

.PageTitleDiv h1 {
	font-size:  42px;
}

.PageTitleDiv h5 {
	font-size:  16px;
}