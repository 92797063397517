/*
* === General Style & Positioning ===
*/

.SurveyResultZone h3 {
    margin-top: 1.3rem;
    margin-bottom: 0.4rem;
    font-weight: bold;
}

.SurveyResultZone p {
    margin-bottom: 0.8rem;
}

.green_zone {
    background-color: rgba(103, 140, 103, 0.262);
    border: 3px solid green;
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    box-shadow:3px 3px 10px rgb(190, 188, 188);
}

.green_zone p {
    padding-left: 0px;
}

.green_zone h1 {
    text-align: center;
    font-weight: bold;
    font-size: 1.8rem;
    color: rgba(36, 35, 35, 0.886);
    margin-bottom: 1rem;
}

.green_zone h2 {
    padding-bottom: 0px;
    color: rgba(36, 35, 35, 0.886);
}

.yellow_zone {
    background-color: rgba(229, 229, 86, 0.519);
    border: 3px solid rgb(230, 224, 53);
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    padding-top: 1rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    box-shadow:3px 3px 10px rgb(190, 188, 188);
}

.yellow_zone h1 {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: rgba(36, 35, 35, 0.886);
    margin-bottom: 1rem;
}

.yellow_zone h2 {
    padding-bottom: 0px;
    color: rgba(36, 35, 35, 0.886);
}

.red_zone {
    background-color: rgba(229, 86, 86, 0.519);
    border: 3px solid rgb(172, 38, 38);
    border-radius: 25px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
    box-shadow:3px 3px 10px rgb(190, 188, 188);
}

.red_zone h1 {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: rgba(36, 35, 35, 0.886);
    margin-bottom: 1rem;
}

.red_zone h2 {
    padding-bottom: 0px;
    color: rgba(36, 35, 35, 0.886);
}